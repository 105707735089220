import HeaderPage from "Components/HeaderPage"
import { cargarGrupoDeAlimento, guardarGrupoDeAlimento} from "Services"
import { Breadcrumb, Button, Col, ColorPicker, Form, Input, InputNumber, Popconfirm, Row, Select, Slider, Space, Spin, Table, Typography, notification } from "antd"
import { Content, Header } from "antd/es/layout/layout"
import {PlusOutlined,SyncOutlined,EditOutlined,DeleteOutlined} from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import { CLASES } from "Contants"

const GrupoAlimentoGuardar=()=>{
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [doc,setDoc] = useState(null)
    const {id} = useParams()
    const [form] = Form.useForm()

    const load=async ()=>{
        if(!id){
            return
        }
        try{
            setLoading(true)
            const data = await cargarGrupoDeAlimento(id)
            setDoc(data)
        }catch(error){
            notification.open({
                type:'error',
                message:error.message
            })
        }finally{
            setLoading(false)
        }
    }

    const save=async (payload)=>{
        try{
           
            setLoading(true)
            const color = typeof payload.color == 'string' ? payload.color :'#'+payload.color.toHex()
            const {message} = await guardarGrupoDeAlimento(id,{...payload,color})
            navigate(-1)
            notification.open({
                type:'success',
                message:message
            })
        }catch(error){
            notification.open({
                type:'error',
                message:error?.message
            })
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        load()
    },[])

    useEffect(()=>{
        if(doc){
            form.setFieldsValue(doc)
        }else{
            form.setFieldValue({color:''})
        }
    },[doc])

    return <div>
        <HeaderPage>
            <Space>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/grupo-de-alimentos">Grupo de alimentos</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{id ? "Editar":"Crear"}</Breadcrumb.Item>
                </Breadcrumb>
            </Space>
        </HeaderPage>
        <Content  style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
        }}>
            <Row justify="center">
                <Col xs={24} sm={12} lg={10} xxl={8}>
                    <Spin spinning={loading}>
                        <Form layout="vertical" onFinish={save} form={form}>
                            <Form.Item label="Color" name="color" rules={[{required:true,message:"Campo requerido"}]}>
                                <ColorPicker/>
                            </Form.Item>
                           
                            <Form.Item label="Nombre" name="nombre" rules={[{required:true,message:"Campo requerido"}]}>
                                <Input/>
                            </Form.Item>
                            
                            <Button type="primary" htmlType="submit">Guardar</Button>
                        </Form> 
                    </Spin>
            </Col>
        </Row>
        </Content>
    </div>
}

export default GrupoAlimentoGuardar