import HeaderPage from "Components/HeaderPage"
import { cargarPlatos } from "Services"
import { Breadcrumb, Button, Popconfirm, Space, Table, Tag, notification } from "antd"
import { Content, Header } from "antd/es/layout/layout"
import {PlusOutlined,SyncOutlined,EditOutlined,LockOutlined} from "@ant-design/icons"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import PlatoRemove from "../Remove"
import useAuth from "Hooks/Auth"
import { invertColor } from "Util"

const Platos=()=>{
    const navigate = useNavigate()
    const {user_in_session} = useAuth()
    const [loading,setLoading] = useState(false)
    const [docs,setDocs] = useState([])
   

    const load=async ()=>{
        try{
            setLoading(true)
            const data = await cargarPlatos({})
            setDocs(data)
        }catch(error){
            notification.open({
                type:'error',
                message:error.message
            })
        }finally{
            setLoading(false)
        }
    }

    const onRemove=(id)=>{
        setDocs(prev=>prev.filter(p=>p.id!=id))
    }

    useEffect(()=>{
        load()
    },[])

    return <div>
        <HeaderPage>
            <Space>
                <Breadcrumb>
                    <Breadcrumb.Item>Platos</Breadcrumb.Item>
                </Breadcrumb>
                <Link to="/plato/crear">
                    <Button icon={<PlusOutlined/>} type="text"/>
                </Link>
                <Button icon={<SyncOutlined/>} onClick={load} type="text"/>
            </Space>
        </HeaderPage>
        <Content  style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
        }}>
            <Table loading={loading} dataSource={docs} pagination={false} rowKey={'_id'}>
                <Table.Column title="Color" dataIndex="color" render={(c)=><div style={{width:24,height:24,background:c}}></div>}/>
                <Table.Column title="Clase" dataIndex="clase"/>
                <Table.Column title="Nombre" dataIndex="nombre"/>
                <Table.Column title="Kcal" dataIndex="calorias"/>
                <Table.Column title="Distribución" render={(d)=>(
                    d?.grupos?.sort((a,b)=>a.porcentaje<b.porcentaje ? 1 : -1).map((g,i)=>(<Tag key={i} style={{color:invertColor(g?.grupo_id?.color,true)}} color={g?.grupo_id?.color}>{g?.grupo_id?.nombre} {g?.porcentaje}%</Tag>))
                )}/>
                
                <Table.Column render={(item)=>(
                    <Space size={24}>
                    <Link to={`/plato/${item.id}/editar`}>
                        <Button icon={<EditOutlined/>} type="text" shape="circle" />
                    </Link>
                    <PlatoRemove id={item.id} onRemove={onRemove}/>
                    </Space>
                )}/>
        </Table>
        </Content>
    </div>
}

export default Platos