import { loadSession } from "Services";
import { Button, Form, Input, notification } from "antd";
import { async } from "q";
import React, { useEffect } from "react";

const BasicInformation = () => {
    
    const [form] = Form.useForm()

    const load = async () => {
        try {
            const data = await loadSession()
            console.log(data)
        } catch (error) {
            console.error(error)
        }
    }      

    const save = (payload) => {
        try{
            console.log(payload)
        }catch(error){
            notification.open({
                type:'error',
                message:'Error!',
                description: error ,
                placement:'top'
              })
      
            // console.error(error)
        }
    }

    useEffect(()=>{
        load()
    },[])


    return(
        <div style={{marginBottom:64}}>
            <h3>Modificar información básica</h3>
            <Form layout="vertical" name="informacionBasica" onFinish={save} form={form}>
                <Form.Item label="E-mail" name="email">
                    <Input type="email" />
                </Form.Item>

                <Form.Item label="Nombre" name="nombre" rules={[{required:true,message:"Campo requerido"}]}>
                    <Input />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                    Guardar
                </Button>
            </Form>
        </div>
    )
}

export default BasicInformation