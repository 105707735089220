import Circle from 'Components/Circle';
import { JWT_KEY } from 'Contants';
import useAuth from 'Hooks/Auth';
import { login } from 'Services';
import { Button, Card, Checkbox, Form, Input, Row, Spin, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';


const Login=()=>{
    console.log("Hola")
    const {setLoadingSession} = useAuth()
    const [loading,setLoading] = useState(false)

    const onFinish = async (values) => {
        try{
            setLoading(true)
            await login(values)
            setLoadingSession(true)
        }catch(error){
            notification.open({
                type:'error',
                message:error?.message || error.toString()
            })
        }finally{
            setLoading(false)
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        

                <div style={{margin:'0 auto',width:'90%',maxWidth:600,boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',marginTop:128,marginBottom:128,borderRadius:16,overflow:'hidden'}}>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div style={{flex:1}}>
                            <img style={{maxWidth:300,width:'100%',height:'100%'}} src="https://plus.unsplash.com/premium_photo-1671403964050-f7756da6c60b?auto=format&fit=crop&q=80&w=1887&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"/>
                        </div>
                        <div style={{flex:1,padding:16,textAlign:'center'}}>
                            <Typography.Title style={{color:'#213e01'}}>Diabetrics</Typography.Title>
                            <Typography.Paragraph>Estilo de vida saludable</Typography.Paragraph>
                            <br></br>
                            <br></br>
                        <Spin spinning={loading}>
                        <Form
                        name="basic" layout='vertical'
                        initialValues={{
                        remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                            required: true,
                            message: 'Por favor ingresa tu email!',
                            },
                        ]}
                        >
                        <Input />
                        </Form.Item>

                        <Form.Item
                        label="Contraseña"
                        name="password"
                        rules={[
                            {
                            required: true,
                            message: 'Por favor ingresa tu contraseña!',
                            },
                        ]}
                        >
                        <Input.Password />
                        </Form.Item>


                        <Button type="primary" htmlType="submit">
                            Acceder
                        </Button>
                       
                    </Form>
                        </Spin>
                        </div>
                    </div>
                   
                </div>
           
    )
}

export default Login