import HeaderPage from "Components/HeaderPage"
import { API, cargarGruposDeAlimentos } from "Services"
import { Badge, Table, Upload,Button, Breadcrumb, Space, notification, Row, Col, Statistic, Card, Modal } from "antd"
import { useEffect, useRef, useState } from "react"
import {UploadOutlined,SyncOutlined} from "@ant-design/icons"
import { API_URL, JWT_KEY } from "Contants"
import dayjs from "dayjs"
import Prediccion from "Components/Deteccion"
import { Radar } from "react-chartjs-2";
import Dragger from "antd/es/upload/Dragger"

const Detecciones=()=>{

    const [docs,setDocs] = useState([])
    const [cargando,setCargando] = useState(false)
    const [subiendo,setSubiendo] = useState(false)
    const [detalle,setDetalle] = useState(null)
    const token = localStorage.getItem(JWT_KEY)
    const [radar,setRadar]=useState([0,0,0,0,0])
    const [grupos,setGrupos] = useState([])

    const columns=[
        {
            title:'Fecha',
            dataIndex:'fecha_inicio',
            render:(f)=>dayjs(f).format("YYYY/MM/DD h:m a")
        },
        {
            title:'Usuario',
            dataIndex:'usuario_id',
            render:(u)=>[u?.first_name || '',u?.last_name || ''].join(" ")
        },
        {
            title:'Duración',
            render:(doc)=>{
                const ms=dayjs(doc?.fecha_fin).diff(doc?.fecha_inicio)
                return `${ms} ms`
            }
        },
        {
            title:'Imagen',
            render:(doc)=>{
                return <div onClick={()=>setDetalle(doc)}><Prediccion imagen={doc?.imagen} ancho_deseado={200} detecciones={doc?.detecciones}  /></div>
            }
        },
        {
            title:'Resumen',
            render:(doc)=>{
                return doc?.detecciones?.map((d,i)=><div key={i}><Space >
                <div style={{background:d?.plato?.color,width:16,height:16}}></div>
                <span>{d?.plato?.nombre}</span>
                <span>{d?.plato?.calorias} kcal</span>
            </Space></div>)
            }
        }
    ]

    const subida_props={
        name: 'imagen',
        showUploadList:false,
        action: `${API_URL}prediccion/run/`,
        headers: {
            authorization: `Bearer ${token}`,
        },
        accept:'image',
        onChange:(info)=>{
            console.log(info)
            
            if (info.file.status === 'uploading') {
                setSubiendo(true)
            }
            if (info.file.status === 'error') {
                setSubiendo(false)
                notification.open({
                    type:'error',
                    message:'No se pudo procesar la imagen'
                })
            }
            if (info.file.status === 'done') {
                setSubiendo(false)
                notification.open({
                    type:'success',
                    message:'Proceso terminado'
                })
                cargar()
            }
        },
        onDrop(e) {
            console.log(e.dataTransfer)
            console.log('Dropped files', e.dataTransfer.files);
        }
    }

    const miga_pan=[
        {
            key:'1',
            title:'Historico de detecciones'
        }
    ]

    const cargar=async ()=>{
        try{
            setDocs([])
            setCargando(true)
            const {data} = await API('prediccion/')
            setDocs(data)
        }catch(error){

        }finally{
            setCargando(false)
        }
    }

    useEffect(()=>{
        cargar()
        cargarGruposDeAlimentos().then((data)=>setGrupos(data))
    },[])

    useEffect(()=>{
        if(grupos.length && docs.length){
            const _radar=grupos.map(g=>({id:g.id,total:0}))
            
            docs.forEach((p)=>{
                p?.detecciones?.forEach((d)=>{
                   if(d?.plato?.grupos){
                    d.plato.grupos.forEach((g)=>{
                        _radar.find(r=>r.id==g.grupo_id.id).total+=d.plato.calorias*g.porcentaje/100
                    })
                   }
                })
            })
            setRadar(_radar)
        }
    },[grupos,docs])

    return <div >
            <Modal width={1024} footer={[]} open={detalle} onCancel={()=>setDetalle(null)}>
                <Space size={32}>
                <Prediccion imagen={detalle?.imagen} ancho_deseado={600} detecciones={detalle?.detecciones} />
                <div>
                    {detalle?.detecciones?.map((d,i)=><div key={i}>
                        <Space >
                        <div style={{background:d?.color?.color,width:16,height:16}}></div>
                        <span>{d?.plato?.nombre}</span>
                        <span>{(d?.probabilidad*100).toFixed(2)} %</span>
                        <span>{d?.plato?.calorias} Calorias</span>
                    </Space>
                    </div>)}
                </div>
                </Space>
            </Modal>

            <HeaderPage>
                <Space>
                <Breadcrumb items={miga_pan}/>
                <Button  type="text" loading={cargando} icon={<SyncOutlined/>} onClick={cargar}>Recargar</Button>
                <Upload {...subida_props} >
                    <Button  type="text" disabled={cargando} loading={subiendo} icon={<UploadOutlined />}>Registrar detección</Button>
                </Upload>
                </Space>
            </HeaderPage>
            
                <div style={{padding:24}}>
                <Dragger openFileDialogOnClick={false} {...subida_props}>
                    <Row gutter={16} style={{paddingTop:16,marginBottom:16}}>
                    <Col xs={24} sm={12} lg={6} xxl={4}>
                        <Card>
                        <Statistic title="Kcal" value={docs?.reduce((a,b)=>a+b?.detecciones?.reduce((a,b)=>a+b?.plato?.calorias,0),0)}/>
                        </Card>
                        
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                        <Card>
                        <Radar options={
                        {
                                plugins: {
                                    legend: {
                                    display: false
                                    }
                                }
                            
                        }} data={{
                            labels:grupos?.map(g=>(g.nombre)),
                            datasets:[
                                {
                                    label:'kcal',
                                    fill:true,
                                    data:radar.map(r=>r.total),
                                    backgroundColor: '#00a3e0',
                                    borderColor: '#005eb8',
                                    
                                },
                                {
                                    label:'kcal',
                                    fill:true,
                                    data:[0,0,0,0,0],
                                
                                }
                            ]
                        }}/>
                        </Card>
                    </Col>
                    </Row>
                </Dragger>
                <Badge.Ribbon text="Demostración" size="big">
            <Table rowKey={'id'} pagination={false}  loading={cargando} dataSource={docs} columns={columns}/>
            </Badge.Ribbon>
            
                </div>
    </div>
}

export default Detecciones