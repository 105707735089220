import HeaderPage from "Components/HeaderPage"
import { changePassword, loadUser, loadUsers, saveUser } from "Services"
import { Breadcrumb, Button, Col, Form, Input, Popconfirm, Row, Space, Spin, Table, notification } from "antd"
import { Content, Header } from "antd/es/layout/layout"
import {PlusOutlined,SyncOutlined,EditOutlined,DeleteOutlined} from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"

const ChangePassword=()=>{

    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [from,setFrom] = useState(null)
    const {id} = useParams()
    const location = useLocation()
    

    const save=async (payload)=>{
        try{
            setLoading(true)
            const {message}=await changePassword(id,payload)
            notification.open({
                type:'success',
                message:message
            })
        }catch(error){
            notification.open({
                type:'error',
                message:error.message
            })
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(location?.state?.from){
            sessionStorage.setItem("cpfrom",location?.state?.from)
        }
        const f = (location?.state?.from || sessionStorage.getItem("cpfrom")).split("-")
        setFrom(f)
    },[])

    return (<div>
        <HeaderPage>
            {from?.length && <Space>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        {from[0] == 'eps' && <Link to="/configuracion/eps">Entidad Prestadora de Salud</Link>}
                        {from[0] == 'doc' && <Link to="/configuracion/medicos">Personal médico</Link>}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{from[1]}</Breadcrumb.Item>
                    <Breadcrumb.Item>Actualizar contraseña</Breadcrumb.Item>
                </Breadcrumb>
            </Space>}
        </HeaderPage>
        <Content  style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
        }}>
            <Row justify="center">
                <Col xs={24} sm={12} lg={10} xxl={8}>
                    <Spin spinning={loading}>
                        <Form layout="vertical" onFinish={save}>
                        
                         
                            <Form.Item label="Contraseña" name="clave" rules={[{required:true,message:"Campo requerido"}]}>
                                <Input.Password/>
                            </Form.Item>
                            <Form.Item label="Confirmar contraseña" name="clave2" 
                            rules={[
                                {required:true,message:"Campo requerido"},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if (!value || getFieldValue('clave') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Las contraseñas no coinciden!'));
                                    },
                                }),
                            ]}
                            >
                                <Input.Password/>
                            </Form.Item>
                            <Button type="primary" htmlType="submit">Guardar</Button>
                        </Form> 
                    </Spin>
            </Col>
        </Row>
        </Content>
    </div>)
}

export default ChangePassword