import { Skeleton, Space,Layout } from "antd"
const {Content,Header,Sider} = Layout

const Loadingpage=()=>{
    return <Layout style={{height:'100vh'}}>
    <Sider style={{background:'transparent'}}>
        <Skeleton.Node style={{width:200,height:'100vh'}} active/>
    </Sider>
    <Layout>
   
  
        
        <Content
        style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 280
        }}
    >
            <Skeleton active/> 
            <Skeleton active/> 
    </Content>
        
   
    </Layout>
    </Layout>
}

export default Loadingpage