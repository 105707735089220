import { Result, Space, Spin, Typography } from "antd"
import { useEffect, useRef, useState } from "react"
import {BugOutlined} from "@ant-design/icons"

const Deteccion=({p,img})=>{
    const bw = img?.ancho_falso*0.02
    const color = p?.plato?.color

    const style={
        left:p?.x1/img?.ancho_real*img?.ancho_falso-bw,
        top:p?.y1/img?.alto_real*img?.alto_falso-bw,
        width:(p?.x2-p?.x1)/img?.ancho_real*img?.ancho_falso,
        height:(p?.y2-p?.y1)/img?.alto_real*img?.alto_falso,
        border:`${bw}px solid ${color}`,
        background:`${color}40`,
        borderRadius:`${bw}px`
    }
    
    return <div className="deteccion" style={style}>
        <span style={{background:color}}>{p?.plato?.nombre || p?.clase} {(p?.probabilidad*100).toFixed(2)} %</span>
    </div>
}

const Prediccion=({imagen,ancho_deseado,detecciones})=>{
    const [cargando,setCargando] = useState(true)
    const _img = useRef(null)
    const [props,setProps] = useState({alto_falso:150,ancho_falso:ancho_deseado})
    const [error,setError] = useState(null)

    const onLoad=()=>{
        const elem = _img.current
        setCargando(false)
        setProps({
            ancho_falso:elem.width,
            alto_falso:elem.height,
            ancho_real:elem.naturalWidth,
            alto_real:elem.naturalHeight
        })
    }

    const onError=(error)=>{
        setCargando(false)
        setError(true)
    }



    if(error){
        return <Space size={16}>
            <BugOutlined size={24}/>
            <Typography.Text danger>Imagen no cargada</Typography.Text>
        </Space>
    }

    return <Spin spinning={cargando}>
            <div className="prediccion" style={{width:props?.ancho_falso,height:props?.alto_falso}}>
                <img ref={_img} src={imagen} onLoad={onLoad} onError={onError} width={ancho_deseado} style={{}}/>
                {detecciones?.map((p,i)=><Deteccion key={i} p={p} img={props} />)}
            </div>
        </Spin>

}

export default Prediccion