import { useState } from "react"
import { Button, Popconfirm, notification } from "antd"
import {DeleteOutlined} from "@ant-design/icons"
import { borrarGrupoAlimento } from "Services"

const PlatoRemove=({id,onRemove})=>{
    const [loading,setLoading] = useState(false)

    const remove=async ()=>{
        try{
            setLoading(true)
            await borrarGrupoAlimento(id)
            onRemove(id)
        }catch({message}){
            notification.open({
                type:'error',
                message
            })
        }finally{
            setLoading(false)
        }
    }

    return  (
        <Popconfirm title="Se borrara el médico" onConfirm={remove}>
            <Button loading={loading}  icon={<DeleteOutlined/>} danger type="text" shape="circle" />
        </Popconfirm>
    )
}

export default PlatoRemove