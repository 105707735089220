import {Button,Layout,Space,Dropdown} from "antd"
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DownOutlined
  } from '@ant-design/icons';
import useAuth from "Hooks/Auth";

const { Header } = Layout;

const HeaderPage=({children})=>{
    const {setCollapsed,collapsed,user_in_session,logout} = useAuth()

    const user_actions=[
        {
            key:'1',
            label:(
                <a href="/usuario/perfil">
                 Perfil
                </a>
            )
        },
        {
            key:'2',
            label:(
                <a onClick={logout}>
                 Cerrar sesión
                </a>
            )
        }
    ]

    return <Header style={{background:'white',padding:0,position: 'sticky',
    top: 0,
    zIndex: 1,}}>
            <div style={{display:'flex',justifyContent:'space-between',marginRight:16}}>
                <Space>
                    <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64,
                    }}
                    />
                    {children}
                </Space>
                <div>
                <Dropdown
                    menu={{
                    items:user_actions,
                    }}
                >
                    <a onClick={(e) => e.preventDefault()}>
                    <Space>
                    {user_in_session.rol} {user_in_session.nombre}
                        <DownOutlined />
                    </Space>
                    </a>
                </Dropdown>
                </div>
            </div>
    </Header>
}

export default HeaderPage