import { API_URL, JWT_KEY } from "Contants"
import axios from "axios"

const sleep=(t)=>{
    return new Promise((resolve)=>setTimeout(resolve,t))
}


export const API=axios.create({
    baseURL:API_URL,
})

API.interceptors.request.use(
    config => {
        const token = localStorage.getItem(JWT_KEY)
        if(token && !config?.url.includes("/login/")){
            config.headers['Authorization'] = `Bearer ${token}`;
        }
      
        return config;
      },
      error => {
          return Promise.reject(error);
      }
);

export const login=async (payload)=>{
    try{
        const {data} = await API.post('usuario/login/',payload)
        const {access_token} = data?.data

        localStorage.setItem(JWT_KEY,access_token)
        return access_token
    }catch(error){
        const message = error?.response?.data?.detail || error.toString()
        return Promise.reject({message})
    }
}

export const loadSession=async ()=>{
    try{
        const {data} = await API('usuario/session/')
        return data?.data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

//-----------Grupos de alimentos


export const cargarGruposDeAlimentos=async (params)=>{
    return API('configuracion/grupo-alimento/',{params}).then(({data})=>data).catch((error)=>{
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    })
}

export const cargarGrupoDeAlimento=async (id)=>{
   
    return await API('configuracion/grupo-alimento/'+id+'/').then(({data})=>data).catch((error)=>{
        console.error(error)
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    })
   
}

export const guardarGrupoDeAlimento=async (id,payload)=>{
    try{
        const method = id ? "put" :"post"
        const url = id ? `configuracion/grupo-alimento/${id}/` : "configuracion/grupo-alimento/"

        const {data} = await API({
            url,
            method,
            data:payload
        })


        return data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

export const borrarGrupoAlimento=async (id)=>{
    try{
        const {data} = await API.delete(`configuracion/grupo-alimento/${id}/`)
        return data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}
//-------------Platos
export const cargarPlatos=async (params)=>{
    return API('configuracion/plato/',{params}).then(({data})=>data).catch((error)=>{
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    })
}

export const cargarPlato=async (id)=>{
   
    return await API('configuracion/plato/'+id+'/').then(({data})=>data).catch((error)=>{
        console.error(error)
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    })
   
}

export const guardarPlato=async (id,payload)=>{
    try{
        const method = id ? "put" :"post"
        const url = id ? `configuracion/plato/${id}/` : "configuracion/plato/"

        const {data} = await API({
            url,
            method,
            data:payload
        })


        return data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

export const borrarPlato=async (id)=>{
    try{
        const {data} = await API.delete(`configuracion/plato/${id}/`)
        return data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

//----------------_Seguridad y Usuario
export const changePassword=async (id,payload)=>{
    try{
        const {data} = await API.patch('usuario/'+id+'/password',payload)
        return data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

export const removeUser=async (id)=>{
    try{
        const {data} = await API.delete('usuario/'+id)
        return data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

export const loadExamenByIde=async (ide)=>{
    try{
        const {data} = await API('medical-report/'+ide)
        
        return data.data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

export const loadExamenByIdeRange=async (params)=>{
    try{
        const {data} = await API('medical-report/by-range',{params})
        return data.data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

export const loadDepartamentos=async (params)=>{
    try{
        const {data} = await API('departamentos',{params})
        return data.data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

export const saveExamen=async (id,payload)=>{
    try{
        const url=id ? `examen/${id}` : 'examen'
        const method=id ? 'put' : 'post'

        const {data} = await API({
            url,
            method,
            data:payload
        })
        return data
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

export const findExamenBySolicitud=async (numero_solicitud)=>{
    try{
        const {data} = await API('examen/numero-solicitud/'+numero_solicitud)
        if(data?.success){
            return data.data
        }
        return null
    }catch(error){
        const message = error?.response?.data?.message || error.toString()
        return Promise.reject({message})
    }
}

export const loadExamens=async (params)=>{
    console.log(params)
   return API('examen',{params}).then(({data})=>data?.data)
}

export const deleteExamen=async (id)=>{
   return API.delete('examen/'+id).then(({data})=>data?.data).catch((error)=>{
        return Promise.reject({message:error?.response?.data?.message || error.toString()})
   })
}

