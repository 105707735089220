import useAuth from "Hooks/Auth"

  import {   Layout, Menu, theme } from 'antd';
import { useState } from 'react';
const { Sider} = Layout;
const { Outlet, useLocation } = require("react-router")

const Master=()=>{
    const location = useLocation()
    const {user_in_session,logout,getMenu,collapsed, setCollapsed} = useAuth()
    const [] = useState(false);
    const {
        token: { colorBgContainer,colorPrimary},
    } = theme.useToken();

  

    return <Layout  style={{minHeight:'100vh',background:'whitesmoke'}}>
      <Sider width={300} trigger={null} collapsible collapsed={collapsed} style={{background:'#005eb8'}}>
        <center style={{padding:24}}>
          <div className="logo1" style={{color:'whitesmoke',textAlign:'center',fontSize:24,letterSpacing:1,lineHeight:'.4'}}>DIABETRICS</div>
          <small style={{color:'whitesmoke',fontSize:8}}>361° EN FUNCION DE LA VIDA</small>
          <small class="logo2" style={{color:'white',fontWeight:'bold'}}>D</small>
        </center>
     
        <Menu
          style={{background:'transparent'}}
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          items={[...getMenu()]}
        />
    </Sider>
    
      
      <Layout style={{background:'white'}}>
      <Outlet/>
      </Layout>
        
    
  </Layout>
}

export default Master