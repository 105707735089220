export const JWT_KEY='diabetrics-token'
export const API_URL=process.env.REACT_APP_API_URL

export const CLASES=[
'arroz-oriental',
'arroz-pollo',
'bandeja-paisa',
'desayuno-americano',
'empanada',
'hamburguesa',
'hotdog',
'parrillada',
'pasta',
'pescado-frito',
'pescado-yuca',
'plato-arabe',
'pollo-asado',
'pollo-frito',
'sandwich'
]