import './App.css';
import {
  Route,
  Routes
} from "react-router-dom";
import "./index.css";
import Login from 'Pages/Login';
import Home from 'Pages/Home';
import AuthRequired from 'Components/AuthRequired';
import Master from 'Pages/Master';
import ChangePassword from 'Pages/ChangePassword';
import Profile from 'Pages/Profile';
import Alimentacion from 'Pages/Deteccion';
import Platos from 'Pages/Platos/List';
import PlatoGuardar from 'Pages/Platos/Save';
import GrupoAlimentoListado from 'Pages/GrupoAlimento/List';
import GrupoAlimentoGuardar from 'Pages/GrupoAlimento/Save';


function App() {
  return (
      <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route element={<Master/>}>
          <Route path='/' element={
            <AuthRequired>
              <Home/>
            </AuthRequired>
          }/>
           <Route path='/usuario/:id/cambiar-clave' element={
            <AuthRequired>
              <ChangePassword/>
            </AuthRequired>
          }/>
           <Route path='/usuario/perfil' element={
            <AuthRequired>
              <Profile />
            </AuthRequired>
          }/>
          <Route path='/predicciones' element={
            <AuthRequired>
              <Alimentacion />
            </AuthRequired>
          }/>
          <Route path='/platos' element={
            <AuthRequired>
              <Platos />
            </AuthRequired>
          }/>
          <Route path='/plato/crear' element={
            <AuthRequired>
              <PlatoGuardar />
            </AuthRequired>
          }/>
          <Route path='/plato/:id/editar' element={
            <AuthRequired>
              <PlatoGuardar />
            </AuthRequired>
          }/>
          <Route path='/grupo-de-alimentos' element={
            <AuthRequired>
              <GrupoAlimentoListado />
            </AuthRequired>
          }/>
          <Route path='/grupo-de-alimento/crear' element={
            <AuthRequired>
              <GrupoAlimentoGuardar />
            </AuthRequired>
          }/>
          <Route path='/grupo-de-alimento/:id/editar' element={
            <AuthRequired>
              <GrupoAlimentoGuardar />
            </AuthRequired>
          }/>
        </Route>
       
      </Routes>
  );
}

export default App;
