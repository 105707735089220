import React, { useEffect, useState } from "react"
import { Breadcrumb, Button, Col, Form, Input, Row, Spin, notification } from "antd"
import { Content } from "antd/es/layout/layout"
import HeaderPage from "Components/HeaderPage"
import { changePassword, loadSession, } from "Services"
import BasicInformation from "./BasicInformation"
import useAuth from "Hooks/Auth"

const Profile = () => {
    const {user_in_session} = useAuth()
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm()

    const load = async () =>{
        try{
            const data = await loadSession()
            console.log(data)
        }catch(error){
            notification.open({
                type:'error',
                message:'Error!',
                description: error.message,
                placement:'top'
            })
        }
    }

    const save = async (payload) => {
        try{
            if(payload.clave != payload.clave2){
                throw({mesage:'Las contraseñas no coinciden.'})
            }
            console.log(user_in_session)
            const {message} = await changePassword(user_in_session._id,payload)
            notification.open({
                type:'success',
                message
            })
        }catch(error){
            notification.open({
                type:'error',
                message:'Error!',
                description: error.message ,
                placement:'top'
              })
      
            // console.error(error)
        }finally{
            setLoading(false)
        }
    }
    
    useEffect(()=>{
        load()
    },[])
    
    return (
        <div>
            <HeaderPage>
                <Breadcrumb items={[{ title: 'Home' }, { title: 'Perfil' }]} />
            </HeaderPage>
            <Content  style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
            }}>
                <Row justify="center">
                    <Col xs={24} sm={12} lg={10} xxl={8}>
                        <Spin spinning={loading}>
                            <BasicInformation />
                            <h3>Cambiar contraseña</h3>
                            <Form title="Cambiar contraseña" layout="vertical" name="changePassword" onFinish={save} form={form}>

                                <Form.Item label="Contraseña" name="clave" rules={[{required:true,message:"Campo requerido"}]}>
                                    <Input.Password />
                                </Form.Item>
                            
                                <Form.Item label="Confirmar contraseña" name="clave2" rules={[{required:true,message:"Campo requerido"}]}>
                                    <Input.Password />
                                </Form.Item>

                                <Button type="primary" htmlType="submit">
                                    Guardar
                                </Button>
                            </Form>
                        </Spin>
                    </Col>
                </Row>
            </Content>
        </div>
    )
}

export default Profile