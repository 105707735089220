import HeaderPage from "Components/HeaderPage"
import { cargarGruposDeAlimentos, cargarPlato, guardarPlato} from "Services"
import { Breadcrumb, Button, Col, ColorPicker, Form, Input, InputNumber, Popconfirm, Row, Select, Slider, Space, Spin, Table, Typography, notification } from "antd"
import { Content, Header } from "antd/es/layout/layout"
import {PlusOutlined,SyncOutlined,EditOutlined,DeleteOutlined} from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import { CLASES } from "Contants"

const PlatoGuardar=()=>{
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [doc,setDoc] = useState(null)
    const [grupo_alimento,setGrupoAlimento] = useState([])
    const {id} = useParams()
    const [form] = Form.useForm()
    const grupos = Form.useWatch('grupos',form)

    const porcentaje_total = grupos?.reduce((a,b)=>a+b.porcentaje,0)
  

    const load=async ()=>{
        if(!id){
            return
        }
        try{
            setLoading(true)
            const data = await cargarPlato(id)
            setDoc({...data,grupos:data?.grupos?.map((g)=>({
                grupo_id:g.grupo_id?.id,
                porcentaje:g.porcentaje
            }))})
        }catch(error){
            notification.open({
                type:'error',
                message:error.message
            })
        }finally{
            setLoading(false)
        }
    }

    const save=async (payload)=>{
        try{
            // const porcentaje_total = payload.proteina+payload.verdura+payload.fruta+payload.grano+payload.lacteo
            // if(porcentaje_total>100){
            //     throw({message:'La suma de la distribución calorica debe ser <= 100% '})
            // }
            setLoading(true)
            const color = typeof payload.color == 'string' ? payload.color :'#'+payload.color.toHex()
            const {message} = await guardarPlato(id,{...payload,color})
            navigate(-1)
            notification.open({
                type:'success',
                message:message
            })
        }catch(error){
            notification.open({
                type:'error',
                message:error?.message
            })
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        load()

        cargarGruposDeAlimentos().then((data)=>setGrupoAlimento(data))
    },[])

    useEffect(()=>{
        if(doc){
            form.setFieldsValue({...doc,validated_data:[]})
        }else{
            form.setFieldValue({color:'blue'})
        }
    },[doc])

    return <div>
        <HeaderPage>
            <Space>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/platos">Platos</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{id ? "Editar":"Crear"}</Breadcrumb.Item>
                </Breadcrumb>
            </Space>
        </HeaderPage>
        <Content  style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
        }}>
            <Row justify="center">
                <Col xs={24} sm={20} lg={18} xxl={8}>
                    <Spin spinning={loading}>
                        <Form layout="vertical" onFinish={save} form={form}>
                            <Form.Item label="Color" name="color" rules={[{required:true,message:"Campo requerido"}]}>
                                <ColorPicker/>
                            </Form.Item>
                            <Form.Item label="Clase" name='clase' rules={[{required:true,message:"Campo requerido"}]}>
                                <Select options={CLASES.map((c)=>({label:c,value:c}))}/>
                            </Form.Item>
                            <Form.Item label="Nombre" name="nombre" rules={[{required:true,message:"Campo requerido"}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Calorias" name="calorias" rules={[{required:true,message:"Campo requerido"}]}>
                                <InputNumber/>
                            </Form.Item>
                            <Typography.Title level={4}>Distribución calórica por grupo de alimento</Typography.Title>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <Form.List name="grupos" style={{flex:1}}>
                            {(fields,{add,remove}) => (
                                <div>
                                    <div>
                                    {fields.map(({name,...field},index) => (
                                        <Space>
                                        <Form.Item label="Grupo" {...field} name={[name,'grupo_id']}>
                                            <Select style={{width:200}} options={grupo_alimento.map((g,i)=>({value:g.id,label:g.nombre}))}/>
                                        </Form.Item>
                                        <Form.Item label="Porcentaje" {...field} name={[name,'porcentaje']}>
                                            <Slider style={{width:200}} min={0} max={100} tooltip={{open: true,}}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button danger icon={<DeleteOutlined/>} onClick={()=>remove(index)}></Button>
                                        </Form.Item>
                                        </Space>
                                    ))}
                                    </div>
                                    <Button icon={<PlusOutlined/>} onClick={()=>add({grupo_id:'',porcentaje:0})}>Agregar grupo</Button>
                                </div>
                            )}
                            </Form.List>
                            <div style={{width:'35%'}}>
                            <Typography.Title style={{color:porcentaje_total>100 ? "red":"black"}}>{porcentaje_total}%</Typography.Title>
                            {porcentaje_total>100 && <small>La sumatoria debe dar >=100</small>}
                            </div>
                            </div>
                            
                            <br></br>
                            {/* <Form.Item label="% Proteínas" name="proteina">
                                <Slider min={0} max={100} tooltip={{open: true,}}/>
                            </Form.Item>
                            <Form.Item label="% Granos" name="grano">
                                <Slider min={0} max={100} tooltip={{open: true,}}/>
                            </Form.Item>
                            <Form.Item label="% Verduras" name="verdura">
                                <Slider min={0} max={100} tooltip={{open: true,}}/>
                            </Form.Item>
                            <Form.Item label="% Frutas" name="fruta">
                                <Slider min={0} max={100} tooltip={{open: true,}}/>
                            </Form.Item>
                            <Form.Item label="% Lácteos" name="lacteo">
                                <Slider min={0} max={100} tooltip={{open: true,}}/>
                            </Form.Item> */}
                            <Button type="primary" htmlType="submit">Guardar</Button>
                        </Form> 
                    </Spin>
            </Col>
        </Row>
        </Content>
    </div>
}

export default PlatoGuardar