import useAuth from "Hooks/Auth";
import React from "react";
import { Navigate, useLocation } from "react-router";

const AuthRequired=({children})=>{
    const {user_in_session} = useAuth()
    let location = useLocation();

    if(user_in_session){
        return children
    }

    console.log("No hay session")

    return <Navigate to="/login" state={{ from: location }} replace />
}

export default AuthRequired