import { JWT_KEY } from "Contants";
import { loadSession } from "Services";
import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
    MedicineBoxOutlined,
    TagsOutlined,
    FunctionOutlined,
    DeploymentUnitOutlined
  } from '@ant-design/icons';
import { Link } from "react-router-dom";
import Loadingpage from "Components/LoadingPage";
import { notification } from "antd";
import { useMediaQuery } from 'react-responsive'

export const AuthCtx = createContext()

const AuthProvider=({children})=>{
    const [api, contextHolder] = notification.useNotification();
    const location = useLocation()
    const navigate = useNavigate()
    
    const [collapsed, setCollapsed] = useState(false);
    const [loading_session, setLoadingSession] = useState(true)
    const [user_in_session, setUserInSession]=useState(null)

    const isDesktopOrLaptop = useMediaQuery({
     query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

    const notify = (type,title,message) => {
        api[type]({
          message: title,
          description:message
        });
    };

    const _loadSession=async ()=>{
        try{
            const data = await loadSession()
            console.log('usuario en session ',data)
            setUserInSession(data)
        }catch(error){

        }finally{
            setLoadingSession(false)
        }
    }

    const logout=()=>{
        localStorage.removeItem(JWT_KEY)
        sessionStorage.removeItem("pathname")
        navigate('/login', { replace: true });
    }

    const getMenu=()=>{
      

        if(user_in_session?.rol.toLowerCase()=='invitado'){
            return [
                        {
                            key: '/predicciones',
                            icon: <FunctionOutlined size={24} />,
                            label: <Link to="/predicciones">Predicciones</Link>,
                        },
                        {
                            key: '/grupo-de-alimentos',
                            icon: <DeploymentUnitOutlined />,
                            label: <Link to="/grupo-de-alimentos">Grupos alimentos</Link>,
                        },
                        {
                            key: '/platos',
                            icon: <TagsOutlined />,
                            label: <Link to="/platos">Platos</Link>,
                        }
                
                
              ]
        }

        return []
    }

    const value={
        logout,
        getMenu,
        user_in_session,
        setLoadingSession,
        collapsed,
        setCollapsed,
        notify,
        screen:{
            isDesktopOrLaptop,
            isBigScreen,
            isTabletOrMobile,
            isMobile
        }
    }

    useEffect(()=>{
        if(location.pathname!='/login'){
            sessionStorage.setItem("pathname",location.pathname)
        }        
    },[location.pathname])

    useEffect(()=>{
        if(loading_session){
            _loadSession()
        }
    },[loading_session])

    useEffect(()=>{
        if(user_in_session){
            let from = location.state?.from?.pathname || sessionStorage.getItem("pathname") || "/predicciones";
            navigate(from, { replace: true });
        }
    },[user_in_session])

    if(loading_session){
        return <Loadingpage/>
    }

    return <AuthCtx.Provider value={value}>{children}</AuthCtx.Provider>
}

export default AuthProvider